import {Portal} from '@primer/react'
import {Dialog, FeatureFlags} from '@primer/react/experimental'
import {useCallback, useRef} from 'react'
import {CreateIssue, type CreateIssueProps} from '../CreateIssue'
import {useIssueCreateConfigContext} from '../contexts/IssueCreateConfigContext'
import type {OnCreateProps} from '../utils/model'
import type {IssueCreateUrlParams} from '../utils/template-args'
import {CreateIssueDialogHeader} from './CreateIssueDialogHeader'
import {CreateIssueDialogFooter} from './CreateIssueDialogFooter'
import {DisplayMode} from '../utils/display-mode'
import {isFeatureEnabled} from '@github-ui/feature-flags'
import {useSafeClose} from '../hooks/use-safe-close'
import {useIssueCreateDataContext} from '../contexts/IssueCreateDataContext'

type CreateIssueDialogProps = CreateIssueProps

export const CreateIssueDialog = ({
  onCreateSuccess,
  onCancel,
  navigate,
  ...props
}: CreateIssueDialogProps): JSX.Element => {
  const {optionConfig, initialDefaultDisplayMode, setDisplayMode, displayMode} = useIssueCreateConfigContext()
  const {usedStorageKeyPrefix} = useIssueCreateDataContext()
  const {onSafeClose} = useSafeClose({
    storageKeyPrefix: usedStorageKeyPrefix,
    issueFormRef: props.issueFormRef,
    onCancel,
  })

  // These two callbacks wrap the passed in callbacks to ensure that the display mode is reset
  // The reason why it's done here is we have access to the create context safely.
  const resetDisplayModeOnClose = useCallback(() => {
    onCancel()
    setDisplayMode(initialDefaultDisplayMode)
  }, [initialDefaultDisplayMode, onCancel, setDisplayMode])

  const resetDisplayModeOnCreateSuccess = useCallback(
    ({issue, createMore}: OnCreateProps) => {
      onCreateSuccess({issue, createMore})
      if (!createMore) {
        setDisplayMode(initialDefaultDisplayMode)
      }
    },
    [initialDefaultDisplayMode, onCreateSuccess, setDisplayMode],
  )

  const issueDialogProps = {...props, ...{onCancel: resetDisplayModeOnClose}}
  const createIssueUrlParamsRef = useRef<IssueCreateUrlParams>(null)

  const issues_react_new_select_panel = isFeatureEnabled('issues_react_new_select_panel')

  return (
    <Portal>
      <FeatureFlags
        flags={{
          primer_react_select_panel_with_modern_action_list: issues_react_new_select_panel,
        }}
      >
        <Dialog
          renderHeader={headerProps => (
            <CreateIssueDialogHeader
              createIssueUrlParamsRef={createIssueUrlParamsRef}
              navigate={navigate}
              {...headerProps}
            />
          )}
          renderFooter={() => <CreateIssueDialogFooter onClose={() => onSafeClose()} />}
          renderBody={() => (
            <div className={`${displayMode === DisplayMode.IssueCreation ? 'p-3' : 'p-0'}`}>
              <CreateIssue
                onCreateSuccess={resetDisplayModeOnCreateSuccess}
                createIssueUrlParamsRef={createIssueUrlParamsRef}
                navigate={navigate}
                {...issueDialogProps}
              />
            </div>
          )}
          onClose={onSafeClose}
          returnFocusRef={optionConfig.returnFocusRef}
          width="xlarge"
          height="auto"
          sx={{
            width: '100%',
            margin: 4,
            maxWidth: '800px',
            maxHeight: 'clamp(300px, 80vh, 800px)',
          }}
        />
      </FeatureFlags>
    </Portal>
  )
}

try{ CreateIssueDialog.displayName ||= 'CreateIssueDialog' } catch {}